<script>
import {
	mdiCalendarOutline,
	mdiHistory
} from "@mdi/js"
import {
	getHolidays,
	getEmployeesForTimekeeper, getVacationApplicationsForTimekeeper
} from "@/services/api/requests"
import CardApplicationTimekeeper from "@/components/Card/CardApplicationTimekeeper.vue"
import DialogApplication from "@/components/Dialog/DialogApplication.vue"
import DialogTimekeeperUpload from "@/components/Dialog/DialogTimekeeperUpload.vue"
import {sortAplications} from "@/services/helpers.js"
import {mapGetters} from "vuex";
export default {
	name: "TimekeeperView",
	components: {DialogTimekeeperUpload, DialogApplication, CardApplicationTimekeeper},
	data() {
		return {
			employees: [],
			selectedEmployee: null,
			applications: [],
			sortedApplications: [],
			loadingEmployees: false,
			loadingApplications: false,
			currentYear: 2024,
			holidays: [],
			dialog: false,
			daysAvailable: 28,
			icons: {
				history: mdiHistory,
				calendar: mdiCalendarOutline,
			},
		}
	},
	computed: {
		showNoCurrentApplications() {
			return !this.loadingApplications && this.applications.length === 0
		},
		showEmployees() {
			return !this.loadingEmployees && this.employees.length > 0
		},
		...mapGetters("User", {
			userRole: "getRole",
		}),
		disableCreateAndEditApplication() {
			if (!this.selectedEmployee) return true
			return this.$store.state['App'].disableCreateAndEdit;
		}
	},
	created() {
		this.fetchEmployees()
		this.fetchApplications()
	},
	methods: {
		async fetchEmployees() {
			this.loadingEmployees = true
			const { results } = await getEmployeesForTimekeeper()
			this.employees = results
			this.loadingEmployees = false
		},
		async fetchApplications() {
			this.loadingApplications = true
			const { results } = await getVacationApplicationsForTimekeeper()
			this.applications = results
			await this.fetchHolidays()
			this.loadingApplications = false
			this.sortedApplications = sortAplications(this.applications, this.userRole)
		},
		async fetchHolidays() {
			const { holiday_list } = await getHolidays(this.currentYear)
			this.holidays = holiday_list
		},
		getEmployeeDayAvailable(employeeId){
			return this.employees.find(employee => employee.id === employeeId).days_available
		},
		onCreated() {
			this.selectedEmployee = null
			this.fetchApplications()
		},
		onUploaded() {
			this.fetchEmployees()
			this.fetchApplications()
		},
		checkEmployee(employee) {
			return this.applications.some(application => application.employee.id === employee.id)
		}
	},
}
</script>

<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<div class="d-flex justify-space-between">
					<h1>Заявки сотрудников на отпуск в {{ currentYear }} году</h1>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col
				cols="12"
			>
				<v-card
					outlined
				>
					<v-card-title>
						<h3>Создайте новую заявку за сотрудника</h3>
					</v-card-title>
					<v-card-text>
						<p>Выберете сотрудников из списка, чтобы добавить их отпуск</p>
						<v-autocomplete
							v-if="showEmployees"
							v-model="selectedEmployee"
							:items="employees"
							:item-text="(item) => `${item.last_name} ${item.first_name} ${item.middle_name}`"
							item-value="id"
							:item-disabled="checkEmployee"
							label="Сотрудники"
							flat
							solo
							outlined
							:rules="[v => !!v || 'Выберете сотрудника']"
						>
							<template v-slot:append-outer>
								<v-btn
									color="primary"
									depressed
									rounded
									:disabled="disableCreateAndEditApplication"
									@click="dialog = true"
								>
									Создать заявку
								</v-btn>
							</template>
						</v-autocomplete>
						<p>Или загрузите шаблон со списком сотрудников:</p>
						<DialogTimekeeperUpload
							:disabled="$store.state['App'].disableCreateAndEdit"
							@update="onUploaded"
						/>
						<DialogApplication
							v-if="selectedEmployee"
							v-model="dialog"
							:days-available="getEmployeeDayAvailable(selectedEmployee)"
							:employee-id="selectedEmployee"
							:employee="employees.find(employee => employee.id === selectedEmployee)"
							:current-year="currentYear"
							:holidays="holidays"
							mode="create"
							@update="onCreated"
						/>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-if="loadingApplications">
				<v-progress-linear
					indeterminate
					color="primary"
				/>
				<p class="text-center">
					Загрузка...
				</p>
			</v-col>
			<v-col v-else>
				<CardApplicationTimekeeper
					v-for="(application, index) in sortedApplications"
					:key="application.id"
					:application="application"
					:holidays="holidays"
					:current-year="currentYear"
					:days-available="application.employee.days_available"
					:index="index"
					@update="fetchApplications"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped lang="scss">
</style>