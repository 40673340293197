<script>
import {
	mdiFormatListBulleted,
	mdiHistory
} from "@mdi/js"
import LabelStatus from "@/components/LabelStatus.vue"
import DialogHistory from "@/components/Dialog/DialogHistory.vue"
import ListVacations from "@/components/ListVacations.vue"
import BtnApplicationCancel from "@/components/Buttons/BtnApplicationCancel.vue"
import BtnApplicationEdit from "@/components/Buttons/BtnApplicationEdit.vue"
import DialogApplication from "@/components/Dialog/DialogApplication.vue"
import CardUser from "@/components/Card/CardUser.vue"
import BtnRescheduleDocument from "@/components/Buttons/BtnRescheduleDocument.vue";
import BtnApplicationReschedule from "@/components/Buttons/BtnApplicationReschedule.vue";

export default {
	name: "CardApplicationTimekeeper",
	components: {
		BtnApplicationReschedule, BtnRescheduleDocument,
		CardUser,
		DialogApplication,
		BtnApplicationEdit,
		BtnApplicationCancel, ListVacations, DialogHistory, LabelStatus},
	props: {
		application: {
			type: Object,
			required: true,
		},
		holidays: {
			type: Array,
			default: () => [],
		},
		currentYear: {
			type: Number,
			default: 2024,
		},
		daysAvailable: {
			type: Number,
			default: 28,
		},
		index: {
			type: Number,
			default: 0,
		},
		disable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dialog: false,
			mode: "",
			icons: {
				history: mdiHistory,
				list: mdiFormatListBulleted
			},
		}
	},
	methods: {
		update(payload) {
			this.$emit("update", payload)
		},
		edit(payload) {
			this.$emit("edit", payload)
		},
		onEdit() {
			this.mode = "edit"
			this.dialog = true
		},
		onReschedule() {
			this.mode = "reschedule"
			this.dialog = true
		}
	}
}
</script>

<template>
	<v-card
		outlined
		class="mb-5"
	>
		<v-card-title>
			<div
				class="d-flex flex-wrap"
				style="width: 100%; gap: 4px;"
			>
				<h3>Заявка на отпуск</h3>
				<CardUser
					v-if="application.employee"
					:user="application.employee"
				/>
				<v-spacer />
				<LabelStatus
					:status="application.status"
				/>
			</div>
		</v-card-title>
		<v-card-text>
			<ListVacations
				:vacations="application.vacations"
				:holidays="holidays"
			/>
		</v-card-text>
		<v-card-actions>
			<v-row>
				<v-col
					cols="12"
					sm="auto"
				>
					<DialogHistory
						v-if="application.histories.length"
						:application="application"
					>
						<template #activator="{attrs, on}">
							<v-btn
								rounded
								depressed
								block
								class="px-6"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon class="mr-1">
									{{ icons.history }}
								</v-icon>
								История
							</v-btn>
						</template>
					</DialogHistory>
				</v-col>
				<v-spacer class="d-none d-sm-flex" />
				<BtnApplicationCancel
					:year="currentYear"
					block
					:application="application"
					@update="update"
				/>
				<BtnApplicationEdit
					block
					:disabled="$store.state['App'].disableCreateAndEdit"
					:status="application.status"
					@edit="onEdit"
				/>
				<BtnApplicationReschedule
					block
					:status="application.status"
					@reschedule="onReschedule"
				/>
				<BtnRescheduleDocument
					:id="application.id"
					block
					:preopen="false"
					:status="application.status"
				/>
			</v-row>
		</v-card-actions>
		<DialogApplication
			v-model="dialog"
			:days-available="daysAvailable"
			:current-year="currentYear"
			:holidays="holidays"
			:application-edit="application"
			:employee-id="application.employee.id"
			:employee="application.employee"
			:mode="mode"
			@update="update"
		/>
	</v-card>
</template>

<style scoped lang="scss">
</style>